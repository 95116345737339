<script>
import { SuawInputGroup, SuawParagraph, SuawHeading } from "@/components";
import ResourceFrame from "@/screens/resourcesIndex/components/ResourceFrame.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    SuawInputGroup,
    SuawParagraph,
    SuawHeading,
    ResourceFrame
  }
};
</script>

<template>
  <ResourceFrame resource-side-nav-type="general">
    <SuawInputGroup group-style="default" direction="column" group-gap="quadruple">
      <SuawHeading level="2" content="Privacy Policy" />
      <SuawHeading level="3" content="Introduction" />
      <SuawParagraph
        text="Your privacy is important to us and this notice describes our privacy policy. Our policy is designed to advise you about how we collect, use, and protect the personal information you provide. We respect your privacy and are committed to protecting it through our compliance with this policy. This privacy policy, and all policies linked to this privacy policy and on our Website, apply to the data we process. Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it."
      />
      <SuawHeading level="3" content="Contents" />
      <ol>
        <li>
          <SuawParagraph text="<a href='#p1'>What Information is Collected</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p2'>How, Where and When the Information is Used</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p3'>Who Has Access to the Information, Controllers and Responsible Companies</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p4'>How We Protect Your Information</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p5'>Children</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p6'>Your Rights and How You Can Access, Manage and Correct Your Information</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p7'>Consent</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p8'>Changes to Privacy Policy</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p9'>Privacy Policies and Data Collection From Third Party Websites</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p10'>Child Users under the age of 18</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p11'>California Residents and California Laws</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p12'>Links to our GDRP, CCPA, Social Media and other information</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p13'>Effective Date</a>" />
        </li>
        <li>
          <SuawParagraph text="<a href='#p14'>Questions?</a>" />
        </li>
      </ol>
      <SuawHeading id="p1" level="4" content="1. What Information is Collected" />
      <SuawParagraph
        text="As a data controller, we do not regularly collect personal data like most other websites you may be accustomed to visiting.  We do, however, occasionally collect a variety of data in order to deliver our services, such as open an account for you when you request it, answer a question you may ask us, or fulfill an order pursuant to your request of us and our acceptance (like buying a coffee mug). Of the data we collect, (which we will talk about in more detail below), we do not collect or process what are called “special categories” of data that may risk your rights and freedoms. We ensure that we collect and manage your personal data transparently, fairly, and securely through the use of processors and sub processors that consistently represent they are in compliance with European Union privacy guidelines (“GDPR”) as well as incorporate many of the safeguards of other policies, (please see <a href='/resources/legal/#GDPRFAQ'>GDPR Faqs</a> and <a href='/resources/legal/#ccpa'>CCPA policy</a>)."
      />
      <SuawParagraph
        text="Whenever we collect Personal Information from you we are open and transparent about its use, and you can access the following precise information from us:"
      />
      <ul>
        <li>
          <SuawParagraph text="data we have collected from you" />
        </li>
        <li>
          <SuawParagraph text="the basis on which we are holding it (e.g. because you gave us consent)" />
        </li>
        <li>
          <SuawParagraph text="what we will do with it" />
        </li>
        <li>
          <SuawParagraph text="how long we will hold it for" />
        </li>
        <li>
          <SuawParagraph text="where it is stored" />
        </li>
        <li>
          <SuawParagraph text="who it might be shared with" />
        </li>
        <li>
          <SuawParagraph text="your rights in relation to the data, and" />
        </li>
        <li>
          <SuawParagraph text="information on how you can access and manage this data" />
        </li>
      </ul>
      <SuawParagraph text="We have provided further detail below about the specific types of data we collect, how we collect the data, and our reasons for doing so." />
      <SuawParagraph
        text="(a) Information You Provide to Us Directly:  It should not surprise you that we collect any information you enter on our site, send us through email, or that you give us in any other way. This information does not include information collected through other websites operated by a third party or otherwise collected by someone other than us. You can choose not to provide certain information, but you may not be able to take advantage of all of our services and features."
      />
      <SuawParagraph
        text="(b) Automatic Information:  When you visit a website, you disclose certain information, such as your Internet Protocol (IP) address and the time of your visit. This site, like many other sites, records this basic information about visits to our site through Google Analytics, but we reasonably anonymize IP addresses by blocking part of your IP address so that it cannot be reasonably connected to you or used to identify you.  If you would like more information about Google Analytics you can click <a href='https://marketingplatform.google.com/about/analytics/'>here</a> (<a href='https://marketingplatform.google.com/about/analytics/'>https://marketingplatform.google.com/about/analytics/</a>). "
      />
      <SuawParagraph
        text="(c) We collect two types of information about you. The first type of information we collect is information by which you may be personally identified and you voluntarily provide to us, such as basic account information to set up a member account, such as name and email address. If you have other requests of us, such as purchasing editing services, buying a coffee mug, entering a contest or promotion sponsored by us, or reporting a problem with our Website, you may choose to provide us information such as your name, postal address, email address, telephone number or any other identifier by which you may be contacted by us online or offline (“personally identifiable information”).  We may keep a copy of your correspondence (including your email address) if you write to us. This includes any User Contributions so we can keep track of who submitted what and are able to contact you if we need to regarding your submission. We do not collect personal data relating to special categories, criminal convictions, or information that is likely to result in any risk to your rights and freedoms. "
      />
      <SuawParagraph
        text="The second type of information we collect is information that is about you individually but does not by itself identify you (“non-personal information”). This type of information is generally collected automatically as you navigate through a website, such as IP addresses, which URL you came from, browsing patterns and actions, location data, zip code, operating system of your computer, browser type and information collected through cookies, flash cookies, web beacons and other tracking technologies that helps us continually improve our services to you, but when you use our Website we reasonably anonymize and aggregate this information, so it cannot be used to reasonably identify you. The cookies make your use of the site easier, make the site run more smoothly, help us to estimate our audience size, usage patterns, and maintain a secure site. Cookies are discussed in more detail below (and in our <a href='/resources/legal#cookiepolicy'>Cookie policy</a>), but for now, please know that you are always free to decline our cookies if your browser permits, but some parts of our site may not work properly in that case. We collect the country and postal code your computer is in, but this data is reasonably anonymized and aggregated, which means that it cannot be reasonably connected to you personally, but rather shows us where our visitors are located so we are able to continually try to stay helpful to our members, guests, and fellow writers."
      />
      <SuawParagraph
        text="(d)  Third-Party Collected Information:  We may provide you links to third party pages we also use or that reference us on Facebook, Twitter, Instagram, and Meetup.  These pages are different than our page and we do not control the third parties that host them.  We urge you to be careful about giving out information on these social media websites and message boards, which may be posted and viewed by or transmitted to others and/or the general public.  These third parties may use cookies (along with other tracking technologies) to provide you services or enhance your experience with their products.  Although we encourage these providers to meet our strict guidelines regarding your information, we do not control these parties’ tracking technologies or how they may be used.  If you have questions about a third party uses and protects your information, you should contact them directly.  Please also see further information about <a href='/resources/legal#socialmediapolicy'>social media policy</a>."
      />
      <SuawHeading id="p2" level="4" content="2. How, Where and When the Information is Used" />
      <SuawParagraph
        text="The information we collect is used for administering our business activities and fulfilling any other purpose for which you provide it and consent.  This data is transferred out of the EU with your consent.  You have explicitly consented to us transferring your first and last name, email, and any other information you provide to us to California, United States, in order for us to perform services for you and your requests of us to do so as well as to our processors, sub processors and third-party vendors, who are also located in the United States, which you can review below.  If you do not fill out contact forms or send us your information with your explicit consent, we do not collect your information.  We may use your information:  to carry out our obligations and enforce our rights for contracts entered into between you and us; to prevent fraud; to protect the rights and/or life of an individual; to protect our rights or prevent misuse of our website, property or services; to notify you about changes to our website, new services, or special offers; to recognize you when you return to our Site and remember your preferences; and, when you ask us to use your information for business activities administered by third parties, such as releasing your address information to the delivery service to deliver products that you ordered or provide order information to third parties that help us provide customer service."
      />
      <SuawParagraph
        text="Specifically, if you fill out your first and last name and location on our website, internet boards or social media, you will join others who are looking to join a Shut Up & Write!® event. Although others may well find your name and location, we encourage you to search for others in your location to attend or schedule an event. We may also use this information to help you find a local event to join or how to start and host an event of Shut Up & Write!®; local events for you to attend; or respond to questions or requests you may ask us. Please remember that everything you post on the internet is public and will probably be seen by many people, so be conscious of what you post and we request you do not post anything you do not intend on being public. Also, always be respectful of others; remember, we are one big family community of writers here to help and encourage each other. Please review our <a href='/resources/legal#socialmediapolicy'>social media policy</a>.  We also use the name and emails of our group event hosts to contact them from time to time to inquire about their Shut Up & Write!® event. We collect this data using the lawful basis:  Legitimate Interest, to fulfill our commitments to you under applicable terms of service or other agreements, compliance with a legal obligation, and Consent. We will ask you if you consent if we use your information for another purpose, such as our drawings and give-aways; notify you of important changes to our Website; or to fulfill any other purpose for which you provide us your personal information or which we obtain your consent.  We collect this data using the lawful basis: Legitimate Interest, compliance with a legal obligation, and/or Consent. We do not share or sell your information to third parties for marketing purposes."
      />
      <SuawParagraph
        text="Information that you choose to make public is shared publicly. Although this may seem obvious, we want to make sure that if you share information in your online identification information, in a User Contribution for us to post in our member area or elsewhere with your consent, or in Social Media (like Facebook or Twitter), you realize that this will become public over the internet.  As you probably know, public information may be indexed by search engines or used by third parties.  Please keep this in mind when deciding what you would like to share.  For more discussion on Social Media, you can review our <a href='/resources/legal#socialmediapolicy'>Social Media policy</a>."
      />
      <SuawParagraph
        text="We may use your non-personal information for any purpose, including the collection of information about online activities over time and across third-party websites or other online services (behavioral tracking).  We, like most other websites, may collect this information by using small data text files called “cookies” that are stored on your device’s hard drive (if your web browser permits) that can later be retrieved to identify you to us.  The purpose of a cookie is to tell the web server that you have returned to a specific page.  The cookies make your use of the site easier, make the site run more smoothly, help us estimate our audience size usage patterns, and maintain a secure site."
      />
      <SuawParagraph
        text="By modifying your browser preferences, you have the choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies.  You are always free to decline our cookies if your browser permits, but some parts of our site may not work properly in that case. "
      />
      <SuawParagraph
        text="We may also use Flash cookies, which are similar to cookies but are not managed by the same browser settings that are used for cookies.  To learn how to manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website (<a href='https://www.adobe.com' target='_blank'>www.adobe.com</a>). "
      />
      <SuawParagraph
        text="Further, we may use small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity)."
      />
      <SuawParagraph
        text="We do not process or respond to “Do Not Track” signals or other similar transmissions that indicate a request to disable online tracking of users who visit our site or who use our services. "
      />
      <SuawHeading id="p3" level="4" content="3. Who Has Access to the Information, Controllers and Responsible Companies" />
      <SuawParagraph
        text="Information about our users and their privacy is important to us.  We will only use your information as we outline in this privacy policy agreement with you and in our <a href='/resources/terms-of-service'>Terms of Use</a>. Depending on which of our services you use, more than one company may be the controller of your personal data in our processors and sub processors, discussed below. We will not disclose any personally identifiable information to any third party without first receiving your permission, which includes our processors and sub processors and third-party vendors, below. We do not sell, trade, or rent your personally identifiable information to others. <a href='https://mailchimp.com/legal/data-processing-addendum/' target='_blank'>https://mailchimp.com/legal/data-processing-addendum/</a> <a href='https://mailchimp.com/about/security' target='_blank'>https://mailchimp.com/about/security</a>"
      />
      <SuawParagraph
        text="Occasionally we may be required by law enforcement or judicial authorities to provide personally identifiable information to the appropriate governmental authorities upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation. We may also share business or personal information when we believe, in good faith, that release is necessary to comply with the law; to protect the life of an individual; to enforce or apply our <a href='/resources/terms-of-service'>Terms of Use</a> and other agreements; or protect the rights, property, or safety of Shut Up & Write!®, its affiliates, employees, users, or others."
      />
      <SuawParagraph
        text="As we mentioned above, we may share your information with our processors and sub processors and third-party vendors for example, for cloud services, hosting, payment services, and data storage.  Here is a non-exclusive list of our processors and sub processors and what they are used for. This list is subject to change as our business evolves, and we will notify you by email or posting on this Website as the list evolves."
      />
      <table>
        <tbody>
          <tr>
            <td><p>Sub processor Name</p></td>
            <td><p>Nature of Processing</p></td>
            <td><p>Entity Country:</p></td>
          </tr>
          <tr>
            <td><p>The Rocket Science Group, LLC. d/b/a Mailchimp</p></td>
            <td><p>Cloud Service Provider</p></td>
            <td><p>United States</p></td>
          </tr>
          <tr>
            <td><p>Formagrid, Inc. d/b/a Airtable</p></td>
            <td><p>Cloud Service Provider</p></td>
            <td><p>United States</p></td>
          </tr>
          <tr>
            <td><p>Automattic, Inc. d/b/a WordPress</p></td>
            <td><p>Cloud Service Provider and Website Host</p></td>
            <td><p>United States, South Africa and Ireland</p></td>
          </tr>
          <tr>
            <td><p>GraphCMS</p></td>
            <td><p>Cloud Service Provider</p></td>
            <td><p>Germany, United States</p></td>
          </tr>
          <tr>
            <td><p>Amazon Web Services</p></td>
            <td><p>Cloud Service Provider</p></td>
            <td><p>United States</p></td>
          </tr>
          <tr>
            <td><p>SendGrid</p></td>
            <td><p>Cloud Service Provider</p></td>
            <td><p>United States</p></td>
          </tr>
          <tr>
            <td><p>Auth0</p></td>
            <td><p>Cloud Service Provider</p></td>
            <td><p>United States</p></td>
          </tr>
        </tbody>
      </table>
      <SuawParagraph
        text="While the data protection, privacy, and other laws of the United States might not be as comprehensive as those in your country, our processors and sub processors take many steps to protect your privacy. Mailchimp and Airtable servers and offices are located in the United States, so your information may be transferred to, stored, or processed in the United States. For example, Mailchimp and Automattic represent that they participate in and have certified compliance with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework and/or standard contractual arrangements. If you are in Australia, Mailchimp also represents that it and its subcontractors comply with the Australian Privacy Act. Airtable data processing agreements include the EU Standard Contractual Clauses. WordPress hosts our Website and provides functionality to its functions."
      />
      <SuawParagraph
        text="We use MailChimp and SendGrid to keep records in the cloud.  Mailchimp delivers billions of emails each month for millions of users and uses multiple message transit agents (sometimes called “MTA”) in different world-class data centers around the USA with world-class security measures, which you can read more about here (<a href='https://mailchimp.com/about/security/'>https://mailchimp.com/about/security/</a> or at mailchimp.com). Visitors and members who are located in the EU or Switzerland should also review Mailchimp’s Data Processing Addendum (<a href='https://mailchimp.com/legal/data-processing-addendum/'>https://mailchimp.com/legal/data-processing-addendum/</a>)."
      />
      <SuawHeading id="p4" level="4" content="4. How We Protect Your Information" />
      <SuawParagraph
        text="The importance of security of your personally identifiable information is also very important to us.  We have implemented measures designed to secure your personally identifiable information from accidental loss and from unauthorized access, use, alteration, and disclosure.  For example, we use security software to protect the confidentiality of your personally identifiable information.  We use a Secure Socket Layer (SSL) technology when information is submitted to us online.  We do not store any of your personal data at Shut Up & Write!®, but instead use secure cloud storage through Mailchimp services and others mentioned above. However, please know that when we access your personally identifiable information from our computers, it is protected in several ways with firewalls, data encryption, physical security for our buildings, files and information contained therein and other current industry standards.  In addition, our business practices are reviewed periodically for compliance with policies and procedures governing the security and confidentiality of our information. Our business practices limit employee access to confidential information, and limit the use and disclosure of such information to authorized persons.  Non-personal information that you provide or that we collect also resides on a secure server in our processor and sub processor and cloud infrastructure mentioned above and is only accessible via password."
      />
      <SuawParagraph
        text="The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. "
      />
      <SuawParagraph
        text="Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure.  As a result, while we strive to protect your personally identifiable information, you acknowledge that: (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity, and privacy of any and all information and data exchanged between you and us through this site cannot be guaranteed; and (c) any such information and data may be viewed or tampered with in transit by a third party.  Any transmission of personal information is at your own risk.  We are not responsible for circumvention of any privacy settings or security measures contained on the website or other service."
      />
      <SuawParagraph
        text="In the unlikely event of a breach of our security, we will inform the relevant regulatory body within 72 hours (or other legal requirements) and, if your personal data was included in the breach, we will also inform you."
      />
      <SuawHeading id="p5" level="4" content="5. Children" />
      <SuawParagraph
        text="This website does not provide services or sell products to children under the age of 13.  If you are under the age of 13 years old, please do not enter any information into this site and do not use this site.  If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you are aware of any information we may have collected from a child under 13 years of age, please let us know so that we can delete that information."
      />
      <SuawHeading id="p6" level="4" content="6. Your Rights and How You Can Access, Manage and Correct Your Information" />
      <SuawParagraph
        text="It is important that you keep your personally identifiable information up to date.  We may give you notice of certain changes and updates by sending you a notice, including to the email address you provided us.  It is therefore important you acknowledge and agree that it is your responsibility to maintain a valid e-mail address as a user, review this site, our Terms of Use, and this privacy policy periodically and to be aware of any changes, updates, modifications, additions, or deletions. Your continued use of the site after such changes will constitute your acknowledgment of the modified privacy policy and agreement to abide and be bound by the modified privacy policy. "
      />
      <SuawParagraph
        text="Your personal data is yours and you have rights over it, including but not limited to:"
      />
      <ul>
        <li>
          <SuawParagraph
            text="the right to be informed about the collection and use of your personal data;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right of access to your personal data and any supplementary information;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right to have any errors in your personal data rectified;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right to have your personal data erased;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right to block or suppress the processing of your personal data;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right to move, copy, or transfer your personal data from one IT environment to another;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right to withdraw your consent of our processing of your personal and data;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right to file a complaint with your local supervisory authority;"
          />
        </li>
        <li>
          <SuawParagraph
            text="the right to object to processing of your personal data in certain circumstances; and,"
          />
        </li>
        <li>
          <SuawParagraph
            text="rights related to automated decision-making (i.e. where no humans are involved) and profiling (i.e. where certain personal data is processed to evaluate an individual)"
          />
        </li>
      </ul>
      <SuawParagraph
        text="To manage your data, you can contact us via e-mail with any requests that you may have."
      />
      <SuawParagraph
        text="You can request access to all your personally identifiable information and manage your data, including your withdrawal of consent, by sending an e-mail to us at <a href='mailto:privacy@shutupwrite.com'>privacy@shutupwrite.com</a>."
      />
      <SuawParagraph
        text="We may not accommodate a request to change information if we believe the change would violate a law or cause the information to be incorrect.  Please note that the personally identifiable information and non-personal information that we maintain will be deleted after we determine there is no longer any reason to process your information or otherwise fulfill our contractual obligations to you.  While we do not hold personal data any longer than we need to, the duration will depend on your relationship with us.  However, it may be impossible to completely delete your information from third party servers (if it was submitted to third parties) or without some residual information because of backups.  Also, if you request us to delete your information, you should realize that deleting personal information may affect our ability to deliver services or may result in deleting your account completely. "
      />
      <SuawParagraph
        text="California residents have the right to request specific disclosures about our privacy practices, including telling you about the information we share with third parties for marketing purposes.  To make such a request, please contact us at the addresses below."
      />
      <SuawHeading id="p7" level="4" content="7. Consent" />
      <SuawParagraph
        text="By using our website and choosing to opt-in to our collection of your data, you consent to the collection and use of your personally identifiable information and non-personal information as described in this privacy policy and we assume we have your express permission and consent to use transfer your personally identifiable information and non-personal information as detailed herein."
      />
      <SuawHeading id="p8" level="4" content="8. Changes to Privacy Policy" />
      <SuawParagraph
        text="If our privacy policy or procedures change, we will immediately post those changes to our website.  Any such changes will be effective immediately upon being posted, unless otherwise stated in the change.  By continuing to access or use our services after those changes become effective, you agree to be bound by the revised privacy policy.  The date the privacy policy was last revised is identified below.  We may notify you of changes to this privacy policy, but you are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our website and this privacy policy to check for any changes."
      />
      <SuawHeading id="p9" level="4" content="9. Privacy Policies and Data Collection From Third Party Websites" />
      <SuawParagraph
        text="Except as described in this privacy policy, this document only addresses the use and disclosure of information that we collect from you.  Although we strongly urge third parties to comply with our standards of protecting your data, please consult each website’s privacy policy and terms of use regarding their policies and data collection, as we are not responsible for the practices or policies of third parties and cannot control their collection of information."
      />
      <SuawHeading id="p10" level="4" content="10. Child Users under the age of 18" />
      <SuawParagraph
        text="Information We Collect for Child Users. Our website is not for the use of people under the age of 13 years old.  We do not intentionally or knowingly collect any Personal Information from users of our website under the age of 13. If you are a user under the age of 13, please do not send any Personal Information to us. If we learn we have collected Personal Information from a child user under the age of 13 we will delete that information as quickly as possible. If you believe that a User under the age of 13 may have provided us Personal Information in violation of this Privacy Policy, please contact us at <a href='mailto:privacy@shutupwrite.com'>privacy@shutupwrite.com</a>.  We may limit how we collect, use, and store some of the information of users between 13 and 18 years old. In some cases, this means we will be unable to provide certain functionality of the Service to these users."
      />
      <SuawHeading id="p13" level="4" content="13. Effective Date" />
      <SuawParagraph
        text="This privacy policy is effective as of 11/12/2022."
      />
      <SuawHeading id="p14" level="4" content="14. Questions?" />
      <SuawParagraph
        text="If you have any questions about this privacy policy, the practices of this site, or your dealings with this site, please contact us by sending a letter to our data controller and grievance officer:"
      />
      <SuawParagraph
        text="Warren Saunders<br/>
Shut Up & Write!<br/>
Attn: Privacy Compliance Officer<br/>
2261 Market Street #4978<br/>
San Francisco, CA 94114<br/>
<a href='mailto:privacy@shutupwrite.com'>privacy@shutupwrite.com</a>"
      />
    </SuawInputGroup>
  </ResourceFrame>
</template>
